import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Services = ({ className, contentAlignment, headerHeading }) => {
   const data = useStaticQuery(graphql`
      {
         twentyYears: file(relativePath: { eq: "repeating/why-us/20+-years-experience.svg" }) {
            publicURL
         }
         clientFirstApproach: file(relativePath: { eq: "repeating/why-us/client-first-approach.svg" }) {
            publicURL
         }
         troubleshootingExperts: file(relativePath: { eq: "repeating/why-us/troubleshooting-experts.svg" }) {
            publicURL
         }
         innovativeAndFast: file(relativePath: { eq: "repeating/why-us/innovative-and-fast.svg" }) {
            publicURL
         }
         servingMultipleIndustries: file(relativePath: { eq: "repeating/why-us/serving-multiple-industries.svg" }) {
            publicURL
         }
         familyOwnedDedication: file(relativePath: { eq: "repeating/why-us/family-owned-dedication.svg" }) {
            publicURL
         }
      }
   `);

   return (
      <section className={`${className}`}>
         <div className="container">
            {headerHeading ? (
               <header className="mb-14 md:mb-18 md:text-center">
                  <h2>{headerHeading}</h2>
               </header>
            ) : null}

            <div className={`grid grid-cols-1 md:grid-cols-3 md:gap-x-16 lg:gap-x-32 gap-y-10 md:gap-y-18 ${contentAlignment}`}>
               <div>
                  <img className={`mb-6 md:mb-7 ${contentAlignment}`} src={data.twentyYears.publicURL} alt="25+ Years Experience" />
                  <h3 className="text-lg">25+ Years Experience</h3>
                  <p className="mb-0">Our team knows manufacturing inside and out with our decades of experience.</p>
               </div>
               <div>
                  <img className={`mb-6 md:mb-7 ${contentAlignment}`} src={data.clientFirstApproach.publicURL} alt="Client-First Approach" />
                  <h3 className="text-lg">Client-First Approach</h3>
                  <p className="mb-0">We work with you to meet your specific needs and put your goals first.</p>
               </div>
               <div>
                  <img className={`mb-6 md:mb-7 ${contentAlignment}`} src={data.troubleshootingExperts.publicURL} alt="Troubleshooting Experts" />
                  <h3 className="text-lg">Troubleshooting Experts</h3>
                  <p className="mb-0">We are problem solvers and there’s no project too complicated for us to handle.</p>
               </div>
               <div>
                  <img className={`mb-6 md:mb-7 ${contentAlignment}`} src={data.innovativeAndFast.publicURL} alt="Innovative and Fast" />
                  <h3 className="text-lg">Innovative and Fast</h3>
                  <p className="mb-0">We get the job done fast no matter how complex your project.</p>
               </div>
               <div>
                  <img
                     className={`mb-6 md:mb-7 ${contentAlignment}`}
                     src={data.servingMultipleIndustries.publicURL}
                     alt="Serving Multiple Industries"
                  />
                  <h3 className="text-lg">Serving Multiple Industries</h3>
                  <p className="mb-0">From theme parks to transit companies, we serve a variety of industries.</p>
               </div>
               <div>
                  <img className={`mb-6 md:mb-7 ${contentAlignment}`} src={data.familyOwnedDedication.publicURL} alt="Family-Owned Dedication" />
                  <h3 className="text-lg">Family-Owned Dedication</h3>
                  <p className="mb-0">Our family-owned business strives to treat customers like family too.</p>
               </div>
            </div>
         </div>
      </section>
   );
};

export default Services;
