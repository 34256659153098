import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import ButtonWithIcon from "../Button/ButtonWithIcon";

const Bio = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      avatarBlog: file(relativePath: { eq: "repeating/bio/blog-avatar.png" }) {
        childImageSharp {
          fluid(maxWidth: 130) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      linkedinIcon: file(
        relativePath: { eq: "repeating/bio/linkedin-icon.svg" }
      ) {
        publicURL
      }
      profileImage: file(
        relativePath: { eq: "repeating/bio/ryan-placeholder.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <section className={`${className}`}>
        <div className="container">
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-2 gap-x-20 w-full">
            <div className="w-full">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.profileImage.childImageSharp.fluid}
                alt="About Us"
              />
            </div>
            <div className="w-full">
              <h2>Ryan Franklin</h2>
              <h3 className="text-[22px] font-normal">
                President | Painted Rhino
              </h3>
              <a
                href="https://www.linkedin.com/in/ryan-franklin-23004025"
                target="_blank"
                className="flex gap-x-2 items-center"
              >
                <img src={data.linkedinIcon.publicURL} alt="LinkedIn Icon" />
                Ryan's LinkedIn Profile
              </a>
              <div className="w-full h-[1px] bg-[#CCC] my-12"></div>
              <p className="mb-4">
                Ryan Franklin launched Painted Rhino with a clear yet ambitious
                goal: to transform rough sketches and bold ideas into tangible
                works of art. Nearly 30 years later, Ryan and his family-run
                team have grown Painted Rhino from a garage startup into an
                award-winning fabrication enterprise.
              </p>

              <p className="mb-4">
                From the full-scale space shuttle replica towering over Kennedy
                Space Center to the life-size TIE and X-wing Starfighters at
                Disneyland, Ryan and the Painted Rhino team have brought
                thousands of iconic projects to life. They've since expanded
                into new markets, designing award-winning ground effects kits
                for Ford Motor Company and supplying all of the fiberglass for
                the first double-decker buses in the U.S.
              </p>
              <p className="mb-0">
                Through it all, Ryan has stayed true to his original vision,
                fostering a tight-knit culture of creativity and pushing the
                boundaries of what's possible in fiberglass and plastic
                fabrication.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Bio;
