import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
// import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import IndustriesWeServe from "../components/Repeating/IndustriesWeServe";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import Bio from "../components/Repeating/Bio";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="About Painted Rhino | Fiberglass Manufacturing Company"
            description="Painted Rhino is a family-owned fiberglass and composite manufacturing company based in Riverside, CA and serving nationwide. Learn more about us here."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-6 md:pt-14">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-12 mb-8 md:mb-16">
                  <div className="md:col-start-1 md:col-span-4">
                     <h1>
                        About Painted <br className="hidden md:block" /> Rhino
                     </h1>
                  </div>
                  <div className="md:col-end-13 md:col-span-8 md:pl-18">
                     <p className="mb-0">
                        Painted Rhino started in 1997 as a fiberglass job shop. From there, we saw a niche and we grew our company so that it could
                        fulfill customers’ multiple manufacturing needs beyond fiberglass. We specialize in composite materials, fiberglass, FRP,
                        rotocasting, tooling, mold making and more. To this day, our Riverside-based company is family-owned and we extend this same
                        approach to our employees and customers.{" "}
                     </p>
                  </div>
               </div>

               <div className="hidden md:block">
                  <Img fadeIn={false} loading="eager" fluid={data.aboutUsDesktop.childImageSharp.fluid} alt="About Us" />
               </div>
               <div className="md:hidden">
                  <Img fadeIn={false} loading="eager" fluid={data.aboutUsMobile.childImageSharp.fluid} alt="About Us" />
               </div>
            </div>
         </section>

         <section className="pt-12 pb-18 md:py-28">
            <div className="container">
               <header className="mb-14 md:mb-18 text-center">
                  <h2>Our Core Values</h2>
               </header>
               <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-16 lg:gap-x-18 gap-y-10 md:gap-y-18 text-center">
                  <div>
                     <img className="mb-6 mx-auto" src={data.faith.publicURL} alt="Faith" />
                     <p className="text-2xl mb-0">We believe our faith impacts our success</p>
                  </div>
                  <div>
                     <img className="mb-6 mx-auto" src={data.family.publicURL} alt="Family" />
                     <p className="text-2xl mb-0">We treat employees and clients like family </p>
                  </div>
                  <div>
                     <img className="mb-6 mx-auto" src={data.customerService.publicURL} alt="Customer Service" />
                     <p className="text-2xl mb-0">We stand by our commitment to excellent customer service</p>
                  </div>
               </div>
            </div>
         </section>
         <Bio className="pt-20 md:pt-32 mb-20 md:mb-28" />

         <TestimonialBackgroundImage />

         <WhyUs
            className="pt-22 md:pt-32 pb-20 md:pb-28"
            contentAlignment="md:text-center md:mx-auto"
            headerHeading="The Painted Rhino Difference."
         />

         <IndustriesWeServe className="pb-20 md:pb-32" />

         <CTABackgroundImage />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/About_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/About_Twitter.jpg" }) {
         publicURL
      }
      aboutUsDesktop: file(relativePath: { eq: "about/about-us-desktop.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1440) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      aboutUsMobile: file(relativePath: { eq: "about/about-us-mobile.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1440) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      faith: file(relativePath: { eq: "about/faith.svg" }) {
         publicURL
      }
      family: file(relativePath: { eq: "about/family.svg" }) {
         publicURL
      }
      customerService: file(relativePath: { eq: "about/customer-service.svg" }) {
         publicURL
      }
   }
`;

export default Page;
