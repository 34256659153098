import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

const Button = styled.a`
   ${tw`
        inline-flex 
        items-center 
        text-gray-800 hover:text-gray-800 focus:text-gray-800 active:text-gray-800
        font-bold
        no-underline
        cursor-pointer 
        focus:outline-none 
        transition-all 
        duration-300 
        ease-in-out
        w-auto
    `}
   i {
      ${tw`text-lg font-light text-primary pl-2 transition-all duration-300 ease-in-out`}
   }
   &:hover {
      i {
         ${tw`pl-4`}
      }
   }
`;

export default (props) => (
   <Button
      as={props.as}
      type={props.type}
      className={props.className}
      href={props.href}
      target={props.target}
      rel={props.rel}
      data-modal-open={props.data}
   >
      {props.text} <i className="fas fa-arrow-right"></i>
   </Button>
);
