import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import ButtonWithIcon from "../Button/ButtonWithIcon";

const IndustriesWeServe = ({ className }) => {
   const data = useStaticQuery(graphql`
      {
         industriesWeServe: file(relativePath: { eq: "repeating/industries-we-serve/industries-we-serve-desktop.jpg" }) {
            childImageSharp {
               fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid_withWebp
               }
            }
         }
      }
   `);

   return (
      <section className={`${className}`}>
         <div className="container">
            <div className="grid md:grid-cols-12 md:gap-x-10 md:gap-x-20 gap-y-10 items-center">
               <div className="order-2 md:order-1 md:col-start-1 md:col-span-7">
                  <Img fadeIn={false} loading="eager" fluid={data.industriesWeServe.childImageSharp.fluid} alt="Industries We Serve" />
               </div>
               <div className="order-1 md:order-2 md:col-end-13 md:col-span-5">
                  <h2>Industries We Serve</h2>
                  <p>
                     Painted Rhino works with a wide range of industries and their unique needs. Don’t see your industry here? Contact us today and
                     tell us what we can build for you.
                  </p>
                  <ul className="styled-list mb-7">
                     <li>RV & Mass Transit Companies</li>
                     <li>Theme & Amusement Parks</li>
                     <li>Restaurants, Casinos, and Storefronts</li>
                     <li>Movie Studios</li>
                     <li>Infrastructure</li>
                     <li>Industrial Equipment </li>
                     <li>Medical Equipment</li>
                     <li>Marine</li>
                     <li>Automotive </li>
                     <li>Sporting Goods</li>
                  </ul>
                  <ButtonWithIcon href="/industries-applications/" text="Learn More" />
               </div>
            </div>
         </div>
      </section>
   );
};

export default IndustriesWeServe;
